
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Noto+Sans+Bengali:wght@100;200;300;400;500;600;700&display=swap');

html,
body {
    // font-family: 'Hind Siliguri', sans-serif;
    font-family: 'Noto Sans Bengali', sans-serif;
}


.msq_mcq_answar{
    display: flex;
    align-items: center;
}

.msq_mcq_answar span{
    font-size: 25px;
    padding-right: 10px;
}

.msq_mcq_answar div{
    width: 100%;
}

.msq_answar {
    display: flex;
    align-items: center;
    // background-color: red;
    padding: 10px 20px;
}

.msq_answar div:first-child{
    padding-right: 20px;
}

.upload-photo-btn{
    width: 100%;
    height: 25px;
    line-height: 20px;
    cursor: pointer;
}

.book-preview{
    width: 300px;
    height: 400px;
    background: #ddd;
    cursor: pointer;
    position: relative;
    border: 2px solid #ddd;
}

.book-preview .download-btn {
    position: absolute;
    bottom: 20px;
    left: 33%;
    cursor: pointer;
    border: none;
    padding: 5px 20px;
    opacity: 0.0;
    border-radius: 10;
    
}


.book-details{
    position: absolute;
    bottom: 60px;
    text-align: center;
    cursor: pointer;
    border: none;
    padding: 5px 20px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    width: 100%;
}


.book-preview:hover > .download-btn{
    background: #001529;
    transition: 0.3s ease-in;
    opacity: 1;
    color: #fff;
    border-radius: 10;
}

.pb-5{
    padding: 15px;
}

.center{
    text-align: center;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.padding-reset .ant-card-body {
    padding: 0px;
}

.border-top-dark{
    border-top: 1px solid #e8e8e8;
    padding-top: 10px;
}

.pb-10 {
   padding-bottom: 10px; 
}

.w-100{
    width: 100%;
}



// custom file uploader
.file-uploader {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .file-uploader button {
    background-color: #007aff;
    border: none;
    color: #fff;
    padding: 5px 40px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .file-uploader button:hover {
    background-color: #0062cc;
  }
  
  .file-uploader input[type="file"] {
    display: none;
  }


.sidebar-left > .ant-menu-inline{
    overflow: hidden !important;
    border-right: 2px solid #001529 !important;
}