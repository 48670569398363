.ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: $border-radius-base;
    color: $color_1;
    background-color: $white;
    border-color: #d9d9d9;
  }
  .ant-btn > .anticon {
    line-height: 1;
  }
  .ant-btn,
  .ant-btn:active,
  .ant-btn:focus {
    outline: 0;
  }
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ant-btn:not([disabled]):active {
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn.disabled,
  .ant-btn[disabled] {
    cursor: not-allowed;
  }
  .ant-btn.disabled > *,
  .ant-btn[disabled] > * {
    pointer-events: none;
  }
  .ant-btn-lg {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    border-radius: $border-radius-base;
  }
  .ant-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: $border-radius-base;
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #40a9ff;
    background-color: $white;
    border-color: #40a9ff;
  }
  .ant-btn:hover > a:only-child,
  .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn:hover > a:only-child::after,
  .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:active,
  .ant-btn.active {
    color: #096dd9;
    background-color: $white;
    border-color: #096dd9;
  }
  .ant-btn:active > a:only-child,
  .ant-btn.active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child::after,
  .ant-btn.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-disabled > a:only-child,
  .ant-btn.disabled > a:only-child,
  .ant-btn[disabled] > a:only-child,
  .ant-btn-disabled:hover > a:only-child,
  .ant-btn.disabled:hover > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn-disabled:focus > a:only-child,
  .ant-btn.disabled:focus > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn-disabled:active > a:only-child,
  .ant-btn.disabled:active > a:only-child,
  .ant-btn[disabled]:active > a:only-child,
  .ant-btn-disabled.active > a:only-child,
  .ant-btn.disabled.active > a:only-child,
  .ant-btn[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-disabled > a:only-child::after,
  .ant-btn.disabled > a:only-child::after,
  .ant-btn[disabled] > a:only-child::after,
  .ant-btn-disabled:hover > a:only-child::after,
  .ant-btn.disabled:hover > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn-disabled:focus > a:only-child::after,
  .ant-btn.disabled:focus > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn-disabled:active > a:only-child::after,
  .ant-btn.disabled:active > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after,
  .ant-btn-disabled.active > a:only-child::after,
  .ant-btn.disabled.active > a:only-child::after,
  .ant-btn[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    text-decoration: none;
    background: $white;
  }
  .ant-btn > i,
  .ant-btn > span {
    display: inline-block;
    pointer-events: none;
  }
  .ant-btn-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: $white;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  .ant-btn-primary:hover > a:only-child,
  .ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:hover > a:only-child::after,
  .ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:active,
  .ant-btn-primary.active {
    color: $white;
    background-color: #096dd9;
    border-color: #096dd9;
  }
  .ant-btn-primary:active > a:only-child,
  .ant-btn-primary.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:active > a:only-child::after,
  .ant-btn-primary.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-primary-disabled > a:only-child,
  .ant-btn-primary.disabled > a:only-child,
  .ant-btn-primary[disabled] > a:only-child,
  .ant-btn-primary-disabled:hover > a:only-child,
  .ant-btn-primary.disabled:hover > a:only-child,
  .ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-primary-disabled:focus > a:only-child,
  .ant-btn-primary.disabled:focus > a:only-child,
  .ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-primary-disabled:active > a:only-child,
  .ant-btn-primary.disabled:active > a:only-child,
  .ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-primary-disabled.active > a:only-child,
  .ant-btn-primary.disabled.active > a:only-child,
  .ant-btn-primary[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary-disabled > a:only-child::after,
  .ant-btn-primary.disabled > a:only-child::after,
  .ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-primary-disabled:hover > a:only-child::after,
  .ant-btn-primary.disabled:hover > a:only-child::after,
  .ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-primary-disabled:focus > a:only-child::after,
  .ant-btn-primary.disabled:focus > a:only-child::after,
  .ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-primary-disabled:active > a:only-child::after,
  .ant-btn-primary.disabled:active > a:only-child::after,
  .ant-btn-primary[disabled]:active > a:only-child::after,
  .ant-btn-primary-disabled.active > a:only-child::after,
  .ant-btn-primary.disabled.active > a:only-child::after,
  .ant-btn-primary[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #d9d9d9;
  }
  .ant-btn-ghost {
    color: $color_1;
    background-color: transparent;
    border-color: #d9d9d9;
  }
  .ant-btn-ghost > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-ghost:hover > a:only-child,
  .ant-btn-ghost:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:hover > a:only-child::after,
  .ant-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:active,
  .ant-btn-ghost.active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9;
  }
  .ant-btn-ghost:active > a:only-child,
  .ant-btn-ghost.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:active > a:only-child::after,
  .ant-btn-ghost.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost-disabled,
  .ant-btn-ghost.disabled,
  .ant-btn-ghost[disabled],
  .ant-btn-ghost-disabled:hover,
  .ant-btn-ghost.disabled:hover,
  .ant-btn-ghost[disabled]:hover,
  .ant-btn-ghost-disabled:focus,
  .ant-btn-ghost.disabled:focus,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost-disabled:active,
  .ant-btn-ghost.disabled:active,
  .ant-btn-ghost[disabled]:active,
  .ant-btn-ghost-disabled.active,
  .ant-btn-ghost.disabled.active,
  .ant-btn-ghost[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-ghost-disabled > a:only-child,
  .ant-btn-ghost.disabled > a:only-child,
  .ant-btn-ghost[disabled] > a:only-child,
  .ant-btn-ghost-disabled:hover > a:only-child,
  .ant-btn-ghost.disabled:hover > a:only-child,
  .ant-btn-ghost[disabled]:hover > a:only-child,
  .ant-btn-ghost-disabled:focus > a:only-child,
  .ant-btn-ghost.disabled:focus > a:only-child,
  .ant-btn-ghost[disabled]:focus > a:only-child,
  .ant-btn-ghost-disabled:active > a:only-child,
  .ant-btn-ghost.disabled:active > a:only-child,
  .ant-btn-ghost[disabled]:active > a:only-child,
  .ant-btn-ghost-disabled.active > a:only-child,
  .ant-btn-ghost.disabled.active > a:only-child,
  .ant-btn-ghost[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost-disabled > a:only-child::after,
  .ant-btn-ghost.disabled > a:only-child::after,
  .ant-btn-ghost[disabled] > a:only-child::after,
  .ant-btn-ghost-disabled:hover > a:only-child::after,
  .ant-btn-ghost.disabled:hover > a:only-child::after,
  .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ant-btn-ghost-disabled:focus > a:only-child::after,
  .ant-btn-ghost.disabled:focus > a:only-child::after,
  .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ant-btn-ghost-disabled:active > a:only-child::after,
  .ant-btn-ghost.disabled:active > a:only-child::after,
  .ant-btn-ghost[disabled]:active > a:only-child::after,
  .ant-btn-ghost-disabled.active > a:only-child::after,
  .ant-btn-ghost.disabled.active > a:only-child::after,
  .ant-btn-ghost[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed {
    color: $color_1;
    background-color: $white;
    border-color: #d9d9d9;
    border-style: dashed;
  }
  .ant-btn-dashed > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    color: #40a9ff;
    background-color: $white;
    border-color: #40a9ff;
  }
  .ant-btn-dashed:hover > a:only-child,
  .ant-btn-dashed:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:hover > a:only-child::after,
  .ant-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:active,
  .ant-btn-dashed.active {
    color: #096dd9;
    background-color: $white;
    border-color: #096dd9;
  }
  .ant-btn-dashed:active > a:only-child,
  .ant-btn-dashed.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:active > a:only-child::after,
  .ant-btn-dashed.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed-disabled,
  .ant-btn-dashed.disabled,
  .ant-btn-dashed[disabled],
  .ant-btn-dashed-disabled:hover,
  .ant-btn-dashed.disabled:hover,
  .ant-btn-dashed[disabled]:hover,
  .ant-btn-dashed-disabled:focus,
  .ant-btn-dashed.disabled:focus,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed-disabled:active,
  .ant-btn-dashed.disabled:active,
  .ant-btn-dashed[disabled]:active,
  .ant-btn-dashed-disabled.active,
  .ant-btn-dashed.disabled.active,
  .ant-btn-dashed[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-dashed-disabled > a:only-child,
  .ant-btn-dashed.disabled > a:only-child,
  .ant-btn-dashed[disabled] > a:only-child,
  .ant-btn-dashed-disabled:hover > a:only-child,
  .ant-btn-dashed.disabled:hover > a:only-child,
  .ant-btn-dashed[disabled]:hover > a:only-child,
  .ant-btn-dashed-disabled:focus > a:only-child,
  .ant-btn-dashed.disabled:focus > a:only-child,
  .ant-btn-dashed[disabled]:focus > a:only-child,
  .ant-btn-dashed-disabled:active > a:only-child,
  .ant-btn-dashed.disabled:active > a:only-child,
  .ant-btn-dashed[disabled]:active > a:only-child,
  .ant-btn-dashed-disabled.active > a:only-child,
  .ant-btn-dashed.disabled.active > a:only-child,
  .ant-btn-dashed[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed-disabled > a:only-child::after,
  .ant-btn-dashed.disabled > a:only-child::after,
  .ant-btn-dashed[disabled] > a:only-child::after,
  .ant-btn-dashed-disabled:hover > a:only-child::after,
  .ant-btn-dashed.disabled:hover > a:only-child::after,
  .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ant-btn-dashed-disabled:focus > a:only-child::after,
  .ant-btn-dashed.disabled:focus > a:only-child::after,
  .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ant-btn-dashed-disabled:active > a:only-child::after,
  .ant-btn-dashed.disabled:active > a:only-child::after,
  .ant-btn-dashed[disabled]:active > a:only-child::after,
  .ant-btn-dashed-disabled.active > a:only-child::after,
  .ant-btn-dashed.disabled.active > a:only-child::after,
  .ant-btn-dashed[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger {
    color: $danger;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:hover {
    color: $white;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
  }
  .ant-btn-danger:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:focus {
    color: #ff4d4f;
    background-color: $white;
    border-color: #ff4d4f;
  }
  .ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:active,
  .ant-btn-danger.active {
    color: $white;
    background-color: #cf1322;
    border-color: #cf1322;
  }
  .ant-btn-danger:active > a:only-child,
  .ant-btn-danger.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:active > a:only-child::after,
  .ant-btn-danger.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger-disabled,
  .ant-btn-danger.disabled,
  .ant-btn-danger[disabled],
  .ant-btn-danger-disabled:hover,
  .ant-btn-danger.disabled:hover,
  .ant-btn-danger[disabled]:hover,
  .ant-btn-danger-disabled:focus,
  .ant-btn-danger.disabled:focus,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger-disabled:active,
  .ant-btn-danger.disabled:active,
  .ant-btn-danger[disabled]:active,
  .ant-btn-danger-disabled.active,
  .ant-btn-danger.disabled.active,
  .ant-btn-danger[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-danger-disabled > a:only-child,
  .ant-btn-danger.disabled > a:only-child,
  .ant-btn-danger[disabled] > a:only-child,
  .ant-btn-danger-disabled:hover > a:only-child,
  .ant-btn-danger.disabled:hover > a:only-child,
  .ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-danger-disabled:focus > a:only-child,
  .ant-btn-danger.disabled:focus > a:only-child,
  .ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-danger-disabled:active > a:only-child,
  .ant-btn-danger.disabled:active > a:only-child,
  .ant-btn-danger[disabled]:active > a:only-child,
  .ant-btn-danger-disabled.active > a:only-child,
  .ant-btn-danger.disabled.active > a:only-child,
  .ant-btn-danger[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger-disabled > a:only-child::after,
  .ant-btn-danger.disabled > a:only-child::after,
  .ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-danger-disabled:hover > a:only-child::after,
  .ant-btn-danger.disabled:hover > a:only-child::after,
  .ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-danger-disabled:focus > a:only-child::after,
  .ant-btn-danger.disabled:focus > a:only-child::after,
  .ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-danger-disabled:active > a:only-child::after,
  .ant-btn-danger.disabled:active > a:only-child::after,
  .ant-btn-danger[disabled]:active > a:only-child::after,
  .ant-btn-danger-disabled.active > a:only-child::after,
  .ant-btn-danger.disabled.active > a:only-child::after,
  .ant-btn-danger[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link {
    color: $primary;
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-link:hover > a:only-child,
  .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:hover > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:active,
  .ant-btn-link.active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9;
  }
  .ant-btn-link:active > a:only-child,
  .ant-btn-link.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after,
  .ant-btn-link.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link-disabled,
  .ant-btn-link.disabled,
  .ant-btn-link[disabled],
  .ant-btn-link-disabled:hover,
  .ant-btn-link.disabled:hover,
  .ant-btn-link[disabled]:hover,
  .ant-btn-link-disabled:focus,
  .ant-btn-link.disabled:focus,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link-disabled:active,
  .ant-btn-link.disabled:active,
  .ant-btn-link[disabled]:active,
  .ant-btn-link-disabled.active,
  .ant-btn-link.disabled.active,
  .ant-btn-link[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-link-disabled > a:only-child,
  .ant-btn-link.disabled > a:only-child,
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link-disabled:hover > a:only-child,
  .ant-btn-link.disabled:hover > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link-disabled:focus > a:only-child,
  .ant-btn-link.disabled:focus > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link-disabled:active > a:only-child,
  .ant-btn-link.disabled:active > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-link-disabled.active > a:only-child,
  .ant-btn-link.disabled.active > a:only-child,
  .ant-btn-link[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link-disabled > a:only-child::after,
  .ant-btn-link.disabled > a:only-child::after,
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link-disabled:hover > a:only-child::after,
  .ant-btn-link.disabled:hover > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link-disabled:focus > a:only-child::after,
  .ant-btn-link.disabled:focus > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link-disabled:active > a:only-child::after,
  .ant-btn-link.disabled:active > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after,
  .ant-btn-link-disabled.active > a:only-child::after,
  .ant-btn-link.disabled.active > a:only-child::after,
  .ant-btn-link[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus,
  .ant-btn-link:active {
    border-color: transparent;
  }
  .ant-btn-link-disabled,
  .ant-btn-link.disabled,
  .ant-btn-link[disabled],
  .ant-btn-link-disabled:hover,
  .ant-btn-link.disabled:hover,
  .ant-btn-link[disabled]:hover,
  .ant-btn-link-disabled:focus,
  .ant-btn-link.disabled:focus,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link-disabled:active,
  .ant-btn-link.disabled:active,
  .ant-btn-link[disabled]:active,
  .ant-btn-link-disabled.active,
  .ant-btn-link.disabled.active,
  .ant-btn-link[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: transparent;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-link-disabled > a:only-child,
  .ant-btn-link.disabled > a:only-child,
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link-disabled:hover > a:only-child,
  .ant-btn-link.disabled:hover > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link-disabled:focus > a:only-child,
  .ant-btn-link.disabled:focus > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link-disabled:active > a:only-child,
  .ant-btn-link.disabled:active > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-link-disabled.active > a:only-child,
  .ant-btn-link.disabled.active > a:only-child,
  .ant-btn-link[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link-disabled > a:only-child::after,
  .ant-btn-link.disabled > a:only-child::after,
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link-disabled:hover > a:only-child::after,
  .ant-btn-link.disabled:hover > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link-disabled:focus > a:only-child::after,
  .ant-btn-link.disabled:focus > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link-disabled:active > a:only-child::after,
  .ant-btn-link.disabled:active > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after,
  .ant-btn-link-disabled.active > a:only-child::after,
  .ant-btn-link.disabled.active > a:only-child::after,
  .ant-btn-link[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-round {
    height: 32px;
    padding: 0 16px;
    font-size: 16px;
    border-radius: 32px;
  }
  .ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 0 20px;
    font-size: 18px;
    border-radius: 40px;
  }
  .ant-btn-round.ant-btn-sm {
    height: 24px;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 24px;
  }
  .ant-btn-circle,
  .ant-btn-circle-outline {
    width: 32px;
    height: 32px;
    padding: 0;
    font-size: 16px;
    border-radius: 50%;
  }
  .ant-btn-circle.ant-btn-lg,
  .ant-btn-circle-outline.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 18px;
    border-radius: 50%;
  }
  .ant-btn-circle.ant-btn-sm,
  .ant-btn-circle-outline.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 50%;
  }
  .ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: $white;
    border-radius: inherit;
    opacity: 0.35;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
  }
  .ant-btn .anticon {
    -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn .anticon.anticon-plus > svg,
  .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ant-btn.ant-btn-loading {
    position: relative;
    pointer-events: none;
  }
  .ant-btn.ant-btn-loading::before {
    display: block;
  }
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 29px;
  }
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
    margin-left: -14px;
  }
  .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 24px;
  }
  .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
    margin-left: -17px;
  }
  .ant-btn-group {
    position: relative;
    display: inline-block;
  }
  .ant-btn-group > .ant-btn,
  .ant-btn-group > span > .ant-btn {
    position: relative;
  }
  .ant-btn-group > .ant-btn:hover,
  .ant-btn-group > span > .ant-btn:hover,
  .ant-btn-group > .ant-btn:focus,
  .ant-btn-group > span > .ant-btn:focus,
  .ant-btn-group > .ant-btn:active,
  .ant-btn-group > span > .ant-btn:active,
  .ant-btn-group > .ant-btn.active,
  .ant-btn-group > span > .ant-btn.active {
    z-index: 2;
  }
  .ant-btn-group > .ant-btn:disabled,
  .ant-btn-group > span > .ant-btn:disabled {
    z-index: 0;
  }
  .ant-btn-group-lg > .ant-btn,
  .ant-btn-group-lg > span > .ant-btn {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    border-radius: 0;
    line-height: 38px;
  }
  .ant-btn-group-sm > .ant-btn,
  .ant-btn-group-sm > span > .ant-btn {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 0;
    line-height: 22px;
  }
  .ant-btn-group-sm > .ant-btn > .anticon,
  .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px;
  }
  .ant-btn-group .ant-btn + .ant-btn,
  .ant-btn + .ant-btn-group,
  .ant-btn-group span + .ant-btn,
  .ant-btn-group .ant-btn + span,
  .ant-btn-group > span + span,
  .ant-btn-group + .ant-btn,
  .ant-btn-group + .ant-btn-group {
    margin-left: -1px;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .ant-btn-group .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn:first-child,
  .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0;
  }
  .ant-btn-group > .ant-btn:only-child {
    border-radius: $border-radius-base;
  }
  .ant-btn-group > span:only-child > .ant-btn {
    border-radius: $border-radius-base;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-btn-group-sm > .ant-btn:only-child {
    border-radius: $border-radius-base;
  }
  .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: $border-radius-base;
  }
  .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-btn-group > .ant-btn-group {
    float: left;
  }
  .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-btn:focus > span,
  .ant-btn:active > span {
    position: relative;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 8px;
  }
  .ant-btn-background-ghost {
    color: $white;
    background: transparent !important;
    border-color: $white;
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: $primary;
    background-color: transparent;
    border-color: $primary;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary:hover,
  .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary:active,
  .ant-btn-background-ghost.ant-btn-primary.active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary-disabled,
  .ant-btn-background-ghost.ant-btn-primary.disabled,
  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary-disabled:hover,
  .ant-btn-background-ghost.ant-btn-primary.disabled:hover,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-primary-disabled:focus,
  .ant-btn-background-ghost.ant-btn-primary.disabled:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary-disabled:active,
  .ant-btn-background-ghost.ant-btn-primary.disabled:active,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
  .ant-btn-background-ghost.ant-btn-primary-disabled.active,
  .ant-btn-background-ghost.ant-btn-primary.disabled.active,
  .ant-btn-background-ghost.ant-btn-primary[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger {
    color: $danger;
    background-color: transparent;
    border-color: $danger;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger:hover,
  .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ff4d4f;
    background-color: transparent;
    border-color: #ff4d4f;
  }
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger:active,
  .ant-btn-background-ghost.ant-btn-danger.active {
    color: #cf1322;
    background-color: transparent;
    border-color: #cf1322;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger-disabled,
  .ant-btn-background-ghost.ant-btn-danger.disabled,
  .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ant-btn-background-ghost.ant-btn-danger-disabled:hover,
  .ant-btn-background-ghost.ant-btn-danger.disabled:hover,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-danger-disabled:focus,
  .ant-btn-background-ghost.ant-btn-danger.disabled:focus,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-danger-disabled:active,
  .ant-btn-background-ghost.ant-btn-danger.disabled:active,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
  .ant-btn-background-ghost.ant-btn-danger-disabled.active,
  .ant-btn-background-ghost.ant-btn-danger.disabled.active,
  .ant-btn-background-ghost.ant-btn-danger[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-link {
    color: $primary;
    background-color: transparent;
    border-color: transparent;
    text-shadow: none;
    color: $white;
  }
  .ant-btn-background-ghost.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-link:hover,
  .ant-btn-background-ghost.ant-btn-link:focus {
    color: #40a9ff;
    background-color: transparent;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-link:active,
  .ant-btn-background-ghost.ant-btn-link.active {
    color: #096dd9;
    background-color: transparent;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-link:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-link.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-link-disabled,
  .ant-btn-background-ghost.ant-btn-link.disabled,
  .ant-btn-background-ghost.ant-btn-link[disabled],
  .ant-btn-background-ghost.ant-btn-link-disabled:hover,
  .ant-btn-background-ghost.ant-btn-link.disabled:hover,
  .ant-btn-background-ghost.ant-btn-link[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-link-disabled:focus,
  .ant-btn-background-ghost.ant-btn-link.disabled:focus,
  .ant-btn-background-ghost.ant-btn-link[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-link-disabled:active,
  .ant-btn-background-ghost.ant-btn-link.disabled:active,
  .ant-btn-background-ghost.ant-btn-link[disabled]:active,
  .ant-btn-background-ghost.ant-btn-link-disabled.active,
  .ant-btn-background-ghost.ant-btn-link.disabled.active,
  .ant-btn-background-ghost.ant-btn-link[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
  .ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
  .ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
  .ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
  .ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
  }
  .ant-btn-two-chinese-chars > *:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
  .ant-btn-block {
    width: 100%;
  }
  .ant-btn:empty {
    vertical-align: top;
  }
  a.ant-btn {
    line-height: 30px;
  }
  a.ant-btn-lg {
    line-height: 38px;
  }
  a.ant-btn-sm {
    line-height: 22px;
  }