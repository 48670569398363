/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
    position: fixed;
    z-index: 10;
  }
  

  .ant-alert {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    padding: 8px 15px 8px 37px;
    border-radius: $border-radius-base;
  }
  .ant-alert.ant-alert-no-icon {
    padding: 8px 15px;
  }
  .ant-alert.ant-alert-closable {
    padding-right: 30px;
  }
  .ant-alert-icon {
    position: absolute;
    top: 11.5px;
    left: 16px;
  }
  .ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  .ant-alert-success .ant-alert-icon {
    color: $success-color;
  }
  .ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  .ant-alert-info .ant-alert-icon {
    color: $primary;
  }
  .ant-alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  .ant-alert-warning .ant-alert-icon {
    color: $warning-color;
  }
  .ant-alert-error {
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
  }
  .ant-alert-error .ant-alert-icon {
    color: $danger;
  }
  .ant-alert-close-icon {
    position: absolute;
    top: 8px;
    right: 16px;
    overflow: hidden;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;
  }
  .ant-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-alert-close-text {
    position: absolute;
    right: 16px;
  }
  .ant-alert-with-description {
    position: relative;
    padding: 15px 15px 15px 64px;
    color: $color_1;
    line-height: 1.5;
    border-radius: $border-radius-base;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 15px;
  }
  .ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 24px;
  }
  .ant-alert-with-description .ant-alert-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .ant-alert-with-description .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: $color_2;
    font-size: 16px;
  }
  .ant-alert-with-description .ant-alert-description {
    display: block;
  }
  .ant-alert.ant-alert-close {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-alert-slide-up-leave {
    -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .ant-alert-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
  @-webkit-keyframes antAlertSlideUpIn {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antAlertSlideUpIn {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antAlertSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antAlertSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  