.ant-badge {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    color: unset;
    line-height: 1;
  }
  .ant-badge-count {
    z-index: 10;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: $white;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: $danger;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 1px $white;
            box-shadow: 0 0 0 1px $white;
  }
  .ant-badge-count a,
  .ant-badge-count a:hover {
    color: $white;
  }
  .ant-badge-multiple-words {
    padding: 0 8px;
  }
  .ant-badge-dot {
    z-index: 10;
    width: 6px;
    height: 6px;
    background: $danger;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 0 1px $white;
            box-shadow: 0 0 0 1px $white;
  }
  .ant-badge-count,
  .ant-badge-dot,
  .ant-badge .ant-scroll-number-custom-component {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    -webkit-transform-origin: 100% 0%;
        -ms-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  .ant-badge-status {
    line-height: inherit;
    vertical-align: baseline;
  }
  .ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%;
  }
  .ant-badge-status-success {
    background-color: $success-color;
  }
  .ant-badge-status-processing {
    position: relative;
    background-color: $primary;
  }
  .ant-badge-status-processing::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $primary;
    border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
            animation: antStatusProcessing 1.2s infinite ease-in-out;
    content: '';
  }
  .ant-badge-status-default {
    background-color: #d9d9d9;
  }
  .ant-badge-status-error {
    background-color: $danger;
  }
  .ant-badge-status-warning {
    background-color: $warning-color;
  }
  .ant-badge-status-pink {
    background: #eb2f96;
  }
  .ant-badge-status-magenta {
    background: #eb2f96;
  }
  .ant-badge-status-red {
    background: $danger;
  }
  .ant-badge-status-volcano {
    background: #fa541c;
  }
  .ant-badge-status-orange {
    background: #fa8c16;
  }
  .ant-badge-status-yellow {
    background: #fadb14;
  }
  .ant-badge-status-gold {
    background: $warning-color;
  }
  .ant-badge-status-cyan {
    background: #13c2c2;
  }
  .ant-badge-status-lime {
    background: #a0d911;
  }
  .ant-badge-status-green {
    background: $success-color;
  }
  .ant-badge-status-blue {
    background: $primary;
  }
  .ant-badge-status-geekblue {
    background: #2f54eb;
  }
  .ant-badge-status-purple {
    background: #722ed1;
  }
  .ant-badge-status-text {
    margin-left: 8px;
    color: $color_1;
    font-size: 14px;
  }
  .ant-badge-zoom-appear,
  .ant-badge-zoom-enter {
    -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
            animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .ant-badge-zoom-leave {
    -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
            animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: middle;
  }
  .ant-badge-not-a-wrapper .ant-scroll-number {
    position: relative;
    top: auto;
    display: block;
  }
  .ant-badge-not-a-wrapper .ant-badge-count {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
  @-webkit-keyframes antStatusProcessing {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
              transform: scale(2.4);
      opacity: 0;
    }
  }
  @keyframes antStatusProcessing {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
              transform: scale(2.4);
      opacity: 0;
    }
  }
  .ant-scroll-number {
    overflow: hidden;
  }
  .ant-scroll-number-only {
    display: inline-block;
    height: 20px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-scroll-number-only > p {
    height: 20px;
    margin: 0;
  }
  .ant-scroll-number-symbol {
    vertical-align: top;
  }
  @-webkit-keyframes antZoomBadgeIn {
    0% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
  }
  @keyframes antZoomBadgeIn {
    0% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
  }
  @-webkit-keyframes antZoomBadgeOut {
    0% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
    100% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
  }
  @keyframes antZoomBadgeOut {
    0% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
    100% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
  }
  