.ant-avatar {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: $white;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
  }
  .ant-avatar-image {
    background: transparent;
  }
  .ant-avatar-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
        -ms-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .ant-avatar.ant-avatar-icon {
    font-size: 18px;
  }
  .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
  }
  .ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
        -ms-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px;
  }
  .ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
  }
  .ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
        -ms-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px;
  }
  .ant-avatar-square {
    border-radius: $border-radius-base;
  }
  .ant-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-back-top {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: fixed;
    right: 100px;
    bottom: 50px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .ant-back-top-content {
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: $white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 20px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-back-top-content:hover {
    background-color: $color_1;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-back-top-icon {
    width: 14px;
    height: 16px;
    margin: 12px auto;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
  }
  @media screen and (max-width: 768px) {
    .ant-back-top {
      right: 60px;
    }
  }
  @media screen and (max-width: 480px) {
    .ant-back-top {
      right: 20px;
    }
  }