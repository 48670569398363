.ant-select-auto-complete {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
  }
  .ant-select-auto-complete.ant-select .ant-select-selection {
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    height: 100%;
    margin-right: 0;
    margin-left: 0;
    line-height: 32px;
  }
  .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-right: 12px;
    margin-left: 12px;
  }
  .ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: auto;
  }
  .ant-select-auto-complete.ant-select .ant-select-search--inline {
    position: static;
    float: left;
  }
  .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 0 !important;
  }
  .ant-select-auto-complete.ant-select .ant-input {
    height: 32px;
    line-height: 1.5;
    background: transparent;
    border-width: 1px;
  }
  .ant-select-auto-complete.ant-select .ant-input:focus,
  .ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-select-auto-complete.ant-select .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
    background-color: transparent;
  }
  .ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important;
  }
  .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
    line-height: 40px;
  }
  .ant-select-auto-complete.ant-select-lg .ant-input {
    height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
    line-height: 24px;
  }
  .ant-select-auto-complete.ant-select-sm .ant-input {
    height: 24px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
    display: inline;
    float: none;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-select {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    outline: 0;
  }
  .ant-select ul,
  .ant-select ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-select > ul > li > a {
    padding: 0;
    background-color: $white;
  }
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  .ant-select-arrow > * {
    line-height: 1;
  }
  .ant-select-arrow svg {
    display: inline-block;
  }
  .ant-select-arrow::before {
    display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    display: block;
  }
  .ant-select-arrow .ant-select-arrow-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .ant-select-selection {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: $white;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-radius: $border-radius-base;
    outline: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-select-selection:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-selection__clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 12px;
    text-align: center;
    text-transform: none;
    background: $white;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: color 0.3s ease, opacity 0.15s ease;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
  }
  .ant-select-selection__clear::before {
    display: block;
  }
  .ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select-selection:hover .ant-select-selection__clear {
    opacity: 1;
  }
  .ant-select-selection-selected-value {
    float: left;
    max-width: 100%;
    padding-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-no-arrow .ant-select-selection-selected-value {
    padding-right: 0;
  }
  .ant-select-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-disabled .ant-select-selection {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-disabled .ant-select-selection:hover,
  .ant-select-disabled .ant-select-selection:focus,
  .ant-select-disabled .ant-select-selection:active {
    border-color: #d9d9d9;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-select-disabled .ant-select-selection__clear {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    padding-right: 10px;
    color: rgba(0, 0, 0, 0.33);
    background: #f5f5f5;
  }
  .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
    display: none;
  }
  .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
  }
  .ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 11px;
    margin-left: 11px;
    line-height: 30px;
  }
  .ant-select-selection__rendered::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '.';
    pointer-events: none;
  }
  .ant-select-lg {
    font-size: 16px;
  }
  .ant-select-lg .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-lg .ant-select-selection__rendered {
    line-height: 38px;
  }
  .ant-select-lg .ant-select-selection--multiple {
    min-height: 40px;
  }
  .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
    top: 20px;
  }
  .ant-select-sm .ant-select-selection--single {
    height: 24px;
  }
  .ant-select-sm .ant-select-selection__rendered {
    margin: 0 7px;
    line-height: 22px;
  }
  .ant-select-sm .ant-select-selection--multiple {
    min-height: 24px;
  }
  .ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
    top: 12px;
  }
  .ant-select-sm .ant-select-selection__clear,
  .ant-select-sm .ant-select-arrow {
    right: 8px;
  }
  .ant-select-disabled .ant-select-selection__choice__remove {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
  }
  .ant-select-disabled .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-search__field__wrap {
    position: relative;
    display: inline-block;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    margin-top: -10px;
    overflow: hidden;
    color: #bfbfbf;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
  }
  .ant-select-search__field__placeholder {
    left: 12px;
  }
  .ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    opacity: 0;
    pointer-events: none;
  }
  .ant-select-search--inline {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .ant-select-search--inline .ant-select-search__field__wrap {
    width: 100%;
    height: 100%;
  }
  .ant-select-search--inline .ant-select-search__field {
    width: 100%;
    height: 100%;
    font-size: 100%;
    line-height: 1;
    background: transparent;
    border-width: 0;
    border-radius: $border-radius-base;
    outline: 0;
  }
  .ant-select-search--inline > i {
    float: right;
  }
  .ant-select-selection--multiple {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
  }
  .ant-select-selection--multiple::before,
  .ant-select-selection--multiple::after {
    display: table;
    content: '';
  }
  .ant-select-selection--multiple::after {
    clear: both;
  }
  .ant-select-selection--multiple .ant-select-search--inline {
    position: static;
    float: left;
    width: auto;
    max-width: 100%;
    padding: 0;
  }
  .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    width: 0.75em;
    max-width: 100%;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    height: auto;
    margin-bottom: -3px;
    margin-left: 5px;
  }
  .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px;
  }
  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 24px;
    margin-top: 3px;
    line-height: 22px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    position: relative;
    float: left;
    max-width: 99%;
    margin-right: 4px;
    padding: 0 20px 0 10px;
    overflow: hidden;
    color: $color_1;
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection--multiple .ant-select-selection__choice__disabled {
    padding: 0 10px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    line-height: inherit;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
        -ms-transform: scale(0.83333333) rotate(0deg);
            transform: scale(0.83333333) rotate(0deg);
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove > * {
    line-height: 1;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
    display: inline-block;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove::before {
    display: none;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
    display: block;
  }
  :root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 12px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-selection--multiple .ant-select-arrow {
    top: 16px;
  }
  .ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
    padding-right: 16px;
  }
  .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
  .ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-right: 20px;
  }
  .ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ant-select-open .ant-select-selection {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-combobox .ant-select-arrow {
    display: none;
  }
  .ant-select-combobox .ant-select-search--inline {
    float: none;
    width: 100%;
    height: 100%;
  }
  .ant-select-combobox .ant-select-search__field__wrap {
    width: 100%;
    height: 100%;
  }
  .ant-select-combobox .ant-select-search__field {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
  }
  .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
  .ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 20px;
  }
  .ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: $color_1;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 14px;
    font-variant: initial;
    background-color: $white;
    border-radius: $border-radius-base;
    outline: none;
    -webkit-box-shadow: $box-shadow-base;
            box-shadow: $box-shadow-base;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-menu {
    max-height: 250px;
    margin-bottom: 0;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    outline: none;
  }
  .ant-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 20px;
  }
  .ant-select-dropdown-menu-item-group-title {
    height: 32px;
    padding: 0 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 32px;
  }
  .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
  .ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
    border-radius: 0;
  }
  .ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    overflow: hidden;
    color: $color_1;
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
  }
  .ant-select-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0;
  }
  .ant-select-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px;
  }
  .ant-select-dropdown-menu-item-selected {
    color: $color_1;
    font-weight: 600;
    background-color: #fafafa;
  }
  .ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
  }
  .ant-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e8e8e8;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 32px;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    color: transparent;
    font-weight: bold;
    font-size: 12px;
    text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
    color: rgba(0, 0, 0, 0.87);
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
    display: none;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    display: inline-block;
    color: $primary;
  }
  .ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 12px;
  }
  .ant-select-dropdown-container-open .ant-select-dropdown,
  .ant-select-dropdown-open .ant-select-dropdown {
    display: block;
  }