.ant-anchor {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    padding-left: 2px;
  }
  .ant-anchor-wrapper {
    margin-left: -4px;
    padding-left: 4px;
    overflow: auto;
    background-color: $white;
  }
  .ant-anchor-ink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .ant-anchor-ink::before {
    position: relative;
    display: block;
    width: 2px;
    height: 100%;
    margin: 0 auto;
    background-color: #e8e8e8;
    content: ' ';
  }
  .ant-anchor-ink-ball {
    position: absolute;
    left: 50%;
    display: none;
    width: 8px;
    height: 8px;
    background-color: $white;
    border: 2px solid $primary;
    border-radius: 8px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: top 0.3s ease-in-out;
    transition: top 0.3s ease-in-out;
  }
  .ant-anchor-ink-ball.visible {
    display: inline-block;
  }
  .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
    display: none;
  }
  .ant-anchor-link {
    padding: 7px 0 7px 16px;
    line-height: 1.143;
  }
  .ant-anchor-link-title {
    position: relative;
    display: block;
    margin-bottom: 6px;
    overflow: hidden;
    color: $color_1;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-anchor-link-title:only-child {
    margin-bottom: 0;
  }
  .ant-anchor-link-active > .ant-anchor-link-title {
    color: $primary;
  }
  .ant-anchor-link .ant-anchor-link {
    padding-top: 5px;
    padding-bottom: 5px;
  }